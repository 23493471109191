<svg
  xmlns="http://www.w3.org/2000/svg"
  class="fill-transparent stroke-2 stroke-cyan-500 hover:stroke-cyan-700"
  width="24"
  height="24"
  viewBox="0 0 24 24"
>
  <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
  <path d="M7 11V7a5 5 0 0 1 10 0v4" />
</svg>
