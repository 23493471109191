var _a;
import * as Tone from "tone";
import { grid, synthState, dimens } from "./store";
import { get } from "svelte/store";
// FIX: type to be determined
let synth;
let playIntervalId;
let scales = {
    classic: [
        "B3",
        "C#4",
        "F#4",
        "G#4",
        "C#5",
        "D#5",
        "E5",
        "G#5",
        "B5",
        "C#6",
        "F#6",
        "G#6",
    ],
    pentatonic: [
        "C4",
        "D4",
        "E4",
        "G4",
        "A4",
        "C5",
        "D5",
        "E5",
        "G5",
        "A5",
        "C6",
        "D6",
    ],
    chromatic: [
        "C5",
        "C#5",
        "D5",
        "Eb5",
        "E5",
        "F5",
        "F#5",
        "G5",
        "G#5",
        "A5",
        "Bb5",
        "B5",
    ],
    major: [
        "C4",
        "D4",
        "E4",
        "F4",
        "G4",
        "A4",
        "B4",
        "C5",
        "D5",
        "E5",
        "F5",
        "G5",
    ],
    harmonic_minor: [
        "A4",
        "B4",
        "C5",
        "D5",
        "E5",
        "F5",
        "G#5",
        "A5",
        "B5",
        "C6",
        "D6",
        "E6",
    ],
};
let synthpad;
let synthpadGrid;
let playing;
let activeRowIdx;
let bpm;
let scale;
let selectedScale = scales[(_a = synthpad === null || synthpad === void 0 ? void 0 : synthpad.scale) !== null && _a !== void 0 ? _a : "major"];
const possibleScales = Object.keys(scales);
const unsubSynthState = synthState.subscribe((state) => {
    synthpad = state;
    playing = state.playing;
    activeRowIdx = state.activeRowIdx;
    bpm = state.bpm;
    selectedScale = scales[state.scale];
});
const unsubGrid = grid.subscribe((grid) => {
    synthpadGrid = grid;
});
export const selectScale = (scale) => {
    if (possibleScales.includes(scale))
        selectedScale = scales[scale];
};
export const initAudio = async () => {
    synth = new Tone.PolySynth(Tone.Synth).toDestination();
    synth.set({ detune: -1200 });
};
export const playRow = async (rowIdx) => {
    if (!synth)
        await initAudio();
    let notesToPlay = [];
    synthpadGrid[rowIdx].forEach((isCellActive, idx) => {
        if (isCellActive)
            notesToPlay.push(selectedScale[idx]);
    });
    synth.triggerAttackRelease(notesToPlay, "16n");
};
export const playCell = async (index) => {
    if (!synth)
        await initAudio();
    synth.triggerAttackRelease(selectedScale[index], "16n");
};
export function playGrid() {
    let synthDimens = get(dimens);
    playing = true;
    clearInterval(playIntervalId);
    playIntervalId = setInterval(() => {
        activeRowIdx = (activeRowIdx + 1) % synthDimens.numRows;
        synthState.set(Object.assign(Object.assign({}, synthpad), { activeRowIdx }));
        playRow(activeRowIdx);
    }, (60 * 1000) / bpm);
}
export function pauseGrid() {
    playing = false;
    synthState.set(Object.assign(Object.assign({}, synthpad), { playing: false }));
    clearInterval(playIntervalId);
}
export function stopGrid() {
    clearInterval(playIntervalId);
    synthState.set(Object.assign(Object.assign({}, synthpad), { activeRowIdx: -1, playing: false }));
}
export function clearGrid() {
    let synthDimens = get(dimens);
    if (!synthpadGrid.length)
        return;
    for (let row = 0; row < synthDimens.numRows; row++) {
        for (let col = 0; col < synthDimens.numCols; col++) {
            synthpadGrid[row][col] = false;
        }
    }
    grid.update(() => synthpadGrid);
}
export function destroySubscriptions() {
    unsubSynthState();
    unsubGrid();
}
