<script lang="ts">
  import Synthpad from "./components/Synthpad.svelte";
</script>

<main class="h-full bg-zinc-900">
  <Synthpad />
</main>

<style global lang="postcss">
  @tailwind base;
  @tailwind components;
  @tailwind utilities;
</style>
